import { Modal } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import IconTrash from 'src/icons/IconTrash'

const DeleteModal = ({ title, opened, close, onDelete }) => {
  const handleDelete = () => {
    onDelete()
    close()
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      centered
      size="32%"
      withCloseButton={false}
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col items-center gap-4 px-6 pt-6">
          <IconTrash
            className={'fill-doubleNickel-white stroke-doubleNickel-error-600'}
          />
          <div className="text-based text-center font-bold text-doubleNickel-gray-900">
            {title}
          </div>
        </div>
        <div className="flex w-full flex-row gap-3 border-t pt-5">
          <Button
            variant="outline"
            text="Cancel"
            className="flex-1"
            onClick={close}
          />
          <Button
            text="Confirm"
            variant="error"
            className="flex-1"
            onClick={handleDelete}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
